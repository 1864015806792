import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { graphql } from "gatsby"
import React from "react"
import { Col, Container, Row, useScreenClass } from "react-grid-system"
import Layout from "../components/layout"
import Main from "../components/main"
import SEO from "../components/seo"

const Page404 = (props) => {
  const {
    seoTitle,
    seoDescription,

    openGraphImage,
  } = props.data.contentfulPage
  const screenClass = useScreenClass()
  return (
    <>
      <SEO
        title={seoTitle ? seoTitle : ``}
        description={
          seoDescription && seoDescription.seoDescription
            ? seoDescription.seoDescription
            : ``
        }
        pageUrl={props?.location?.href}
        image={openGraphImage?.file?.url}
      />
      <Layout>
        <Main>
          <section>
            <Container
              style={{
                paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                  ? "3.4rem"
                  : "33px",
                paddingRight: ["md", "lg", "xl"].includes(screenClass)
                  ? "3.4rem"
                  : "33px",
              }}
            >
              <Row>
                <Col lg={8} offset={{ lg: 2 }}>
                  <h1 className={`headline`}>
                    {props.data.contentfulPage.mainHeadline}
                  </h1>
                  {documentToReactComponents(
                    JSON.parse(props.data.contentfulPage.text.raw)
                  )}
                </Col>
              </Row>
            </Container>
          </section>
        </Main>
      </Layout>
    </>
  )
}

export const query = graphql`
  query Query404 {
    contentfulPage(slug: { eq: "404" }) {
      id
      slug
      name
      mainHeadline
      text {
        raw
      }
      seoTitle
      seoDescription {
        seoDescription
      }
      seoHelmet {
        seoHelmet
      }
      openGraphImage {
        file {
          url
        }
      }
    }
  }
`

export default Page404
